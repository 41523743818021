@import url("https://api.tiles.mapbox.com/mapbox-gl-js/v1.11.0/mapbox-gl.css");
@import url("https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v4.5.0/mapbox-gl-geocoder.css");

body {
  margin: 0;
}

.map-parent {
  max-height: calc(100vh - 60px);
  display: flex;
  flex-wrap: wrap;
}

.map__drawer {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  order: 2;
  height: calc(100vh - 60px - 75vh);
  overflow-y: auto;
  transition: height 0.5s, transform 0.5s;
  background-color: white;
  padding: 1rem;
}
.map__drawer > * + * {
  margin-top: 0.5rem;
}
.map__drawer--expanded {
  z-index: 999;
  height: calc(70vh - 60px);
  transform: translate(0, -50vh);
}

.map__expand-button {
  display: block;
}

.map__bounding-box {
  flex-basis: 100%;
  height: 75vh;
  order: 1;
}

@media only screen and (min-width: 600px) {
  .map-parent {
    flex-direction: row;
  }

  .map__drawer {
    flex-basis: 25%;
    height: calc(100vh - 60px);
    order: 1;
  }

  .map__bounding-box {
    flex-basis: 75%;
    height: calc(100vh - 60px);
    order: 2;
  }

  .map__expand-button {
    display: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .loading-icon {
    animation: loading-icon-spin infinite 1s ease-in-out;
    font-size: 2.5rem;
    position: absolute;
    top: 0;
    z-index: 5;
    margin: 10px;
  }
}

@keyframes loading-icon-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.material-type {
  font-weight: bold;
}

.legend span {
  display: inline-block;
  height: 1rem;
  width: 1rem;
  margin-right: 1rem;
}

.mapboxgl-popup {
	max-width: 800px;
  opacity: 90%;
}
.mapboxgl-popup-content {
  padding: 0.05rem 0.2rem;
}
