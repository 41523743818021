.header {
  height: 60px;
  z-index: 100;
}

.topnav {
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 0 1rem;
  gap: 1rem;
}

.topnav__links {
  color: black;
  list-style: none;
}

.topnav__links {
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin-left: 1rem;
  margin-right: 1rem;
}
.topnav__links > * {
  margin-right: 1rem;
}
.topnav__links__tab {
  text-decoration: none;
  font-weight: bold;
  letter-spacing: 0.05rem;
  color: inherit;
}
.topnav__links__tab:visited {
  color: inherit;
}
.topnav__links__tab:hover {
  border-bottom: 2px solid #5ca3ea;
  margin-bottom: -2px;
}
.topnav__links__tab--active, .topnav__links__tab--active:visited {
  color: #5ca3ea;
}

.topnav__language-select {
  align-self: center;
  padding: 0.5rem;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
}

.topnav__button {
    margin: 0px;
    padding: 6px 12px;
    border-radius: 3px;
    border: solid 1px#5ca3ea;
    background-color: #5ca3ea;
    color: white;
    font-weight: bold;
    align-self: center;
}
.topnav__button:hover {
    background-color: #8bbdee;
    cursor: pointer;
}

.desktop-hidden {
  display: none;
  z-index: 100;
}
.mobile-navbar {
  padding: 0 1rem;
  background: white;
}

.mobile-navbar__backdrop {
  display: block;
  position: fixed; /* Stay in place */
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.mobile-navbar__drawer--closed {
  display: none;
}

.mobile-navbar__top {
  display: flex;
  justify-content: space-between;
}

.mobile-navbar__toggle {
  appearance: none;
  background: none;
  border: none;
  align-self: center;
  padding: 0;
  cursor: pointer;
}

.mobile-navbar__drawer ul {
  list-style: none;
}

.mobile-navbar__drawer > ul {
  margin-left: 0;
}

.mobile-navbar__drawer__items {
  padding: 1rem;
  margin: 0;
}
.mobile-navbar__drawer__items > * + * {
  margin-top: 1.5rem;
}

.mobile-navbar__drawer__nested-items {
  padding: 0;
}
.mobile-navbar__drawer__nested-items li {
  margin-top: 0.75rem;
}

.mobile-navbar__btn {
  appearance: none;
  background: white;
  border: none;
  font-size: 1rem;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.mobile-navbar__icon {
  transition: transform 0.35s
}

.mobile-navbar__icon--rotate {
  transform: rotate(180deg);
}

.mobile-navbar__lang-list {
  display: none;
}

.mobile-navbar__lang-list li {
  cursor: pointer;
}

.mobile-navbar__lang-list--open {
  display: block;
}

@media only screen and (max-width: 875px) {
  .desktop-hidden {
    display: block;
  }
  .mobile-hidden {
    display: none;
  }
}