.tabs {
  display: flex;
  margin-bottom: 1rem;
}

.tab {
  /* flex: 1; */
  padding: 0.5rem 2.25rem;
  cursor: pointer;
  border: none;
  background-color: white;
  font-size: 1.2rem;
}
.tab-inactive {
  /* border-bottom: 0.5px solid black; */
  color: grey;
}
.tab-inactive:hover {
  /* border-bottom: 0.5px solid black; */
  background-color: whitesmoke;
}
.tab-active {
  color: #0d97fa;
  border-bottom: 3px solid #0d97fa;
}
.tab-active:hover {
  background-color: #0d97fa1c;
}