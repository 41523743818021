.disclaimer-container {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.disclaimer__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.disclaimer__footer__logo {
  display: flex;
  align-items: center;
}

.disclaimer__button {
  margin: 0px;
  padding: 6px 12px;
  border-radius: 3px;
  border: solid 1px#5ca3ea;
  background-color: #5ca3ea;
  color: white;
  font-weight: bold;
  align-self: center;
}
