.contact-us {
  display: flex;
  flex-direction: column;
}

.contact-us__field {
  margin-bottom: 16px;
}

.contact-us__button {
  padding: 4px 8px;
  margin-right: 8px;
}

.required:after {
  content:" *";
  color: red;
}