.RICHMOND-lead-indicator {
  border: 2px solid #d3d3d3;
  border-radius: 4px;
  background-color: rgba(211, 211, 211, 0.3);
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.RICHMOND-lead-indicator > * {
  margin: 0;
}
.RICHMOND-lead-indicator > * + * {
  margin-top: 0.5rem;
}

.RICHMOND-lead-indicator--known {
  border-color: #fb3939;
  background-color: #fb393930;
}

.RICHMOND-lead-indicator--high {
  border-color: #e8aa44;
  background-color: #e8aa4430;
}

.RICHMOND-lead-indicator--medium {
  border-color: #f7d694;
  background-color: #f7d69430;
}

.RICHMOND-lead-indicator--low {
  border-color: #5ca3ea;
  background-color: #5ca3ea30;
}

.RICHMOND-lead-indicator--none {
  border-color: #174e87;
  background-color: #174e8730;
}

.RICHMOND-lead-indicator--unknown {
  border-color: #d3d3d3;
  background-color: #d3d3d330;
}

.TOLEDO-lead-indicator {
  border: 2px solid #d3d3d3;
  border-radius: 4px;
  background-color: rgba(211, 211, 211, 0.3);
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.TOLEDO-lead-indicator > * {
  margin: 0;
}
.TOLEDO-lead-indicator > * + * {
  margin-top: 0.5rem;
}

.TOLEDO-lead-indicator--known {
  border-color: #F5DC00;
  background-color: rgba(254, 254, 105, 0.3);
}

.TOLEDO-lead-indicator--high {
  border-color: #DDF969;
  background-color: rgba(220, 249, 105, 0.3);
}

.TOLEDO-lead-indicator--medium {
  border-color: #A9F36A;
  background-color: rgba(170, 243, 106, 0.3);
}

.TOLEDO-lead-indicator--low {
  border-color: #78EC6C;
  background-color: rgba(121, 236, 108, 0.3);
}

.TOLEDO-lead-indicator--none {
  border-color: #24a335;
  background-color: rgba(36, 163, 53, 0.3);
}

.TOLEDO-lead-indicator--unknown {
  border-color: #d3d3d3;
  background-color: rgba(211, 211, 211, 0.3);
}
