.pm-modal {
  display: block;
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.pm-modal__content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.pm-modal__close {
  float: right;
}

.pm-modal__close:hover {
  cursor: pointer;
}

.modal-button {
    margin-right: 10px;
    padding: 6px 12px;
    border-radius: 3px;
    border: solid 1px#5ca3ea;
    background-color: #5ca3ea;
    color: white;
    font-weight: bold;
}
