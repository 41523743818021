.use-location-button {
    border-radius: 3px;
    background-color: #5ca3ea;
    color: white;
    padding: 0.5rem 1rem;
    font-weight: bold; 
    border: none;
    text-decoration: none;
    margin-right: 0.5rem;
}

.use-location-button:hover {
    background-color: #8bbdee;
    cursor: pointer;
}

.use-location-button:visited {
    color: white;
}
