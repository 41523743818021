.neighborhood__data-point {
  display: flex;
  margin-bottom: 1.2rem;
}
.neighborhood__data-point :not(:first-child) {
  margin-left: 1rem;
}

.neighborhood__data-point__text {
  margin: 0;
  flex-basis: 50%;
}

.parcel__address {
  font-size: 1rem;
}
.parcel__material-message {
  font-size: 1.2rem;
}