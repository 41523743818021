@import url(https://api.tiles.mapbox.com/mapbox-gl-js/v1.11.0/mapbox-gl.css);
@import url(https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v4.5.0/mapbox-gl-geocoder.css);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap);
body {
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.use-location-button {
    border-radius: 3px;
    background-color: #5ca3ea;
    color: white;
    padding: 0.5rem 1rem;
    font-weight: bold; 
    border: none;
    text-decoration: none;
    margin-right: 0.5rem;
}

.use-location-button:hover {
    background-color: #8bbdee;
    cursor: pointer;
}

.use-location-button:visited {
    color: white;
}

.pm-modal {
  display: block;
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.pm-modal__content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.pm-modal__close {
  float: right;
}

.pm-modal__close:hover {
  cursor: pointer;
}

.modal-button {
    margin-right: 10px;
    padding: 6px 12px;
    border-radius: 3px;
    border: solid 1px#5ca3ea;
    background-color: #5ca3ea;
    color: white;
    font-weight: bold;
}

.disclaimer-container {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.disclaimer__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.disclaimer__footer__logo {
  display: flex;
  align-items: center;
}

.disclaimer__button {
  margin: 0px;
  padding: 6px 12px;
  border-radius: 3px;
  border: solid 1px#5ca3ea;
  background-color: #5ca3ea;
  color: white;
  font-weight: bold;
  align-self: center;
}

.map-instructions__list {
  display: flex;
}

.map-instructions__list li:not(:last-child) {
  margin-bottom: 1rem;
}
.RICHMOND-lead-indicator {
  border: 2px solid #d3d3d3;
  border-radius: 4px;
  background-color: rgba(211, 211, 211, 0.3);
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.RICHMOND-lead-indicator > * {
  margin: 0;
}
.RICHMOND-lead-indicator > * + * {
  margin-top: 0.5rem;
}

.RICHMOND-lead-indicator--known {
  border-color: #fb3939;
  background-color: #fb393930;
}

.RICHMOND-lead-indicator--high {
  border-color: #e8aa44;
  background-color: #e8aa4430;
}

.RICHMOND-lead-indicator--medium {
  border-color: #f7d694;
  background-color: #f7d69430;
}

.RICHMOND-lead-indicator--low {
  border-color: #5ca3ea;
  background-color: #5ca3ea30;
}

.RICHMOND-lead-indicator--none {
  border-color: #174e87;
  background-color: #174e8730;
}

.RICHMOND-lead-indicator--unknown {
  border-color: #d3d3d3;
  background-color: #d3d3d330;
}

.TOLEDO-lead-indicator {
  border: 2px solid #d3d3d3;
  border-radius: 4px;
  background-color: rgba(211, 211, 211, 0.3);
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.TOLEDO-lead-indicator > * {
  margin: 0;
}
.TOLEDO-lead-indicator > * + * {
  margin-top: 0.5rem;
}

.TOLEDO-lead-indicator--known {
  border-color: #F5DC00;
  background-color: rgba(254, 254, 105, 0.3);
}

.TOLEDO-lead-indicator--high {
  border-color: #DDF969;
  background-color: rgba(220, 249, 105, 0.3);
}

.TOLEDO-lead-indicator--medium {
  border-color: #A9F36A;
  background-color: rgba(170, 243, 106, 0.3);
}

.TOLEDO-lead-indicator--low {
  border-color: #78EC6C;
  background-color: rgba(121, 236, 108, 0.3);
}

.TOLEDO-lead-indicator--none {
  border-color: #24a335;
  background-color: rgba(36, 163, 53, 0.3);
}

.TOLEDO-lead-indicator--unknown {
  border-color: #d3d3d3;
  background-color: rgba(211, 211, 211, 0.3);
}

.neighborhood__data-point {
  display: flex;
  margin-bottom: 1.2rem;
}
.neighborhood__data-point :not(:first-child) {
  margin-left: 1rem;
}

.neighborhood__data-point__text {
  margin: 0;
  flex-basis: 50%;
}

.parcel__address {
  font-size: 1rem;
}
.parcel__material-message {
  font-size: 1.2rem;
}
body {
  margin: 0;
}

.map-parent {
  max-height: calc(100vh - 60px);
  display: flex;
  flex-wrap: wrap;
}

.map__drawer {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  order: 2;
  height: calc(100vh - 60px - 75vh);
  overflow-y: auto;
  transition: height 0.5s, -webkit-transform 0.5s;
  transition: height 0.5s, transform 0.5s;
  transition: height 0.5s, transform 0.5s, -webkit-transform 0.5s;
  background-color: white;
  padding: 1rem;
}
.map__drawer > * + * {
  margin-top: 0.5rem;
}
.map__drawer--expanded {
  z-index: 999;
  height: calc(70vh - 60px);
  -webkit-transform: translate(0, -50vh);
          transform: translate(0, -50vh);
}

.map__expand-button {
  display: block;
}

.map__bounding-box {
  flex-basis: 100%;
  height: 75vh;
  order: 1;
}

@media only screen and (min-width: 600px) {
  .map-parent {
    flex-direction: row;
  }

  .map__drawer {
    flex-basis: 25%;
    height: calc(100vh - 60px);
    order: 1;
  }

  .map__bounding-box {
    flex-basis: 75%;
    height: calc(100vh - 60px);
    order: 2;
  }

  .map__expand-button {
    display: none;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .loading-icon {
    -webkit-animation: loading-icon-spin infinite 1s ease-in-out;
            animation: loading-icon-spin infinite 1s ease-in-out;
    font-size: 2.5rem;
    position: absolute;
    top: 0;
    z-index: 5;
    margin: 10px;
  }
}

@-webkit-keyframes loading-icon-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes loading-icon-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.material-type {
  font-weight: bold;
}

.legend span {
  display: inline-block;
  height: 1rem;
  width: 1rem;
  margin-right: 1rem;
}

.mapboxgl-popup {
	max-width: 800px;
  opacity: 90%;
}
.mapboxgl-popup-content {
  padding: 0.05rem 0.2rem;
}

.tabs {
  display: flex;
  margin-bottom: 1rem;
}

.tab {
  /* flex: 1; */
  padding: 0.5rem 2.25rem;
  cursor: pointer;
  border: none;
  background-color: white;
  font-size: 1.2rem;
}
.tab-inactive {
  /* border-bottom: 0.5px solid black; */
  color: grey;
}
.tab-inactive:hover {
  /* border-bottom: 0.5px solid black; */
  background-color: whitesmoke;
}
.tab-active {
  color: #0d97fa;
  border-bottom: 3px solid #0d97fa;
}
.tab-active:hover {
  background-color: #0d97fa1c;
}
.wtd-header {
  color: #5ca3ea;
}

.vertical-tabs-container {
  width: 25%;
  background-color: #f1f1f1;
}

.vertical-tabs {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.vertical-tab {
  text-align: left;
  border-width: 0px;
  font-size: 1.25rem;
}

.vertical-tab--primary {
  background-color: #5ca3ea;
  color: white;
  font-weight: bold;
}

.vertical-tab--not-primary:hover {
  background-color: #dddddd;
  cursor: pointer;
}

.modal {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.topnav__logo {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.topnav__logo img {
  max-height: 25px;
}
.topnav__logo__title {
  font-weight: bold;
}
.topnav__logo__powered-by {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
}

.contact-us {
  display: flex;
  flex-direction: column;
}

.contact-us__field {
  margin-bottom: 16px;
}

.contact-us__button {
  padding: 4px 8px;
  margin-right: 8px;
}

.required:after {
  content:" *";
  color: red;
}
.header {
  height: 60px;
  z-index: 100;
}

.topnav {
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 0 1rem;
  grid-gap: 1rem;
  gap: 1rem;
}

.topnav__links {
  color: black;
  list-style: none;
}

.topnav__links {
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin-left: 1rem;
  margin-right: 1rem;
}
.topnav__links > * {
  margin-right: 1rem;
}
.topnav__links__tab {
  text-decoration: none;
  font-weight: bold;
  letter-spacing: 0.05rem;
  color: inherit;
}
.topnav__links__tab:visited {
  color: inherit;
}
.topnav__links__tab:hover {
  border-bottom: 2px solid #5ca3ea;
  margin-bottom: -2px;
}
.topnav__links__tab--active, .topnav__links__tab--active:visited {
  color: #5ca3ea;
}

.topnav__language-select {
  align-self: center;
  padding: 0.5rem;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
}

.topnav__button {
    margin: 0px;
    padding: 6px 12px;
    border-radius: 3px;
    border: solid 1px#5ca3ea;
    background-color: #5ca3ea;
    color: white;
    font-weight: bold;
    align-self: center;
}
.topnav__button:hover {
    background-color: #8bbdee;
    cursor: pointer;
}

.desktop-hidden {
  display: none;
  z-index: 100;
}
.mobile-navbar {
  padding: 0 1rem;
  background: white;
}

.mobile-navbar__backdrop {
  display: block;
  position: fixed; /* Stay in place */
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.mobile-navbar__drawer--closed {
  display: none;
}

.mobile-navbar__top {
  display: flex;
  justify-content: space-between;
}

.mobile-navbar__toggle {
  -webkit-appearance: none;
          appearance: none;
  background: none;
  border: none;
  align-self: center;
  padding: 0;
  cursor: pointer;
}

.mobile-navbar__drawer ul {
  list-style: none;
}

.mobile-navbar__drawer > ul {
  margin-left: 0;
}

.mobile-navbar__drawer__items {
  padding: 1rem;
  margin: 0;
}
.mobile-navbar__drawer__items > * + * {
  margin-top: 1.5rem;
}

.mobile-navbar__drawer__nested-items {
  padding: 0;
}
.mobile-navbar__drawer__nested-items li {
  margin-top: 0.75rem;
}

.mobile-navbar__btn {
  -webkit-appearance: none;
          appearance: none;
  background: white;
  border: none;
  font-size: 1rem;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.mobile-navbar__icon {
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s
}

.mobile-navbar__icon--rotate {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.mobile-navbar__lang-list {
  display: none;
}

.mobile-navbar__lang-list li {
  cursor: pointer;
}

.mobile-navbar__lang-list--open {
  display: block;
}

@media only screen and (max-width: 875px) {
  .desktop-hidden {
    display: block;
  }
  .mobile-hidden {
    display: none;
  }
}
footer {
  height: auto;
  margin: auto;
  text-align: center;
  margin-top: 2.5rem;
}

footer p {
  margin: 0;
}

footer img {
  width: 100%;
  max-width: 200px;
}
.App {
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

* {
  box-sizing: border-box;
}

html, button {
  /* font-family: montserrat, 'Open Sans', sans-serif; */
  font-family: 'Inter', sans-serif;
}

a {
  color: #5ca3ea;
}
a:visited {
  color: #164e87;
}

html, body, #root, .App {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.App {
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
}

.container {
  max-width: 1140px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

@media only screen and (min-width: 768px) {
  .col-quarter {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media only screen and (min-width: 768px) {
  .col-third {
    flex: 0 0 33%;
    max-width: 33%;
  }
}
@media only screen and (min-width: 768px) {
  .col-half {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media only screen and (min-width: 768px) {
  .col-two-thirds {
    flex: 0 0 67%;
    max-width: 67%;
  }
}
@media only screen and (min-width: 768px) {
  .col-three-quarters {
    flex: 0 0 75%;
    max-width: 75%;
  }
}
.col-quarter,
.col-third,
.col-half,
.col-two-thirds,
.col-three-quarters,
.col-full {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

/* Use to hide elements from sighted users, but not screen readers */
.visually-hidden {
  border: 0;
  clip: rect(0,0,0,0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
}
