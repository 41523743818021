.wtd-header {
  color: #5ca3ea;
}

.vertical-tabs-container {
  width: 25%;
  background-color: #f1f1f1;
}

.vertical-tabs {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.vertical-tab {
  text-align: left;
  border-width: 0px;
  font-size: 1.25rem;
}

.vertical-tab--primary {
  background-color: #5ca3ea;
  color: white;
  font-weight: bold;
}

.vertical-tab--not-primary:hover {
  background-color: #dddddd;
  cursor: pointer;
}

.modal {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
