footer {
  height: auto;
  margin: auto;
  text-align: center;
  margin-top: 2.5rem;
}

footer p {
  margin: 0;
}

footer img {
  width: 100%;
  max-width: 200px;
}