* {
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

html, button {
  /* font-family: montserrat, 'Open Sans', sans-serif; */
  font-family: 'Inter', sans-serif;
}

a {
  color: #5ca3ea;
}
a:visited {
  color: #164e87;
}

html, body, #root, .App {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.App {
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
}

.container {
  max-width: 1140px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

@media only screen and (min-width: 768px) {
  .col-quarter {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media only screen and (min-width: 768px) {
  .col-third {
    flex: 0 0 33%;
    max-width: 33%;
  }
}
@media only screen and (min-width: 768px) {
  .col-half {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media only screen and (min-width: 768px) {
  .col-two-thirds {
    flex: 0 0 67%;
    max-width: 67%;
  }
}
@media only screen and (min-width: 768px) {
  .col-three-quarters {
    flex: 0 0 75%;
    max-width: 75%;
  }
}
.col-quarter,
.col-third,
.col-half,
.col-two-thirds,
.col-three-quarters,
.col-full {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

/* Use to hide elements from sighted users, but not screen readers */
.visually-hidden {
  border: 0;
  clip: rect(0,0,0,0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
}