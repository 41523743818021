.topnav__logo {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.topnav__logo img {
  max-height: 25px;
}
.topnav__logo__title {
  font-weight: bold;
}
.topnav__logo__powered-by {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
}
